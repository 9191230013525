<template>
  <div
    ref="container"
    class="container"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <div
      class="sprite"
      :style="{
        backgroundImage: isInViewport ? `url(${spritesheet})` : 'none'
      }"
    />
    <div class="icon">
      <slot ref="icon" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useIntervalFn, useElementVisibility } from '@vueuse/core'
import { gsap } from 'gsap'

const props = defineProps<{
  spritesheet: string
  frames: number
  frameRate: number
  repeat?: boolean
}>()

const container = ref<HTMLElement | null>(null)
const frame = ref(0)
const isHovering = ref(false)

const isInViewport = useElementVisibility(container)

const spriteInterval = useIntervalFn(
  () => {
    const prevFrame = (frame.value - 1) % props.frames
    const nextFrame = (frame.value + 1) % props.frames

    if (isHovering.value) {
      // Advance frames but stop at last frame if there's no repeat
      if (!(nextFrame === 0 && !props.repeat)) frame.value = nextFrame
    } else {
      // Reverse frames
      if (prevFrame !== -1) frame.value = prevFrame

      // Start reversing to 2D when going back to the first few frames
      const shouldStartReversing =
        props.repeat || frame.value <= props.frames / 4

      if (shouldStartReversing && !dimensionAnimation?.isActive())
        dimensionAnimation?.reverse()
    }
  },
  props.frameRate,
  { immediate: false }
)

let dimensionAnimation: gsap.core.Timeline | null = null

useGsapContext(() => {
  if (!container.value) return

  dimensionAnimation = gsap
    .timeline({ paused: true })
    .to(container.value.querySelector('.icon'), {
      scale: 0.7,
      duration: 0.1,
      ease: 'power2.in',
      onReverseComplete: () => spriteInterval.pause()
    })
    .to(container.value.querySelector('.icon'), {
      scale: 0,
      duration: 0,
      onComplete: () => spriteInterval.resume()
    })
    .fromTo(
      container.value.querySelector('.sprite'),
      { scale: 0.7, visibility: 'hidden' },
      {
        scale: 1,
        visibility: 'visible',
        duration: 0.1,
        ease: 'power2.out'
      }
    )
})

const onMouseEnter = () => {
  isHovering.value = true
  dimensionAnimation?.play()
}

const onMouseLeave = () => {
  isHovering.value = false
  // Let spriteInterval gracefully reverse spritesheet before animating back to 2D
  if (!spriteInterval.isActive.value) dimensionAnimation?.reverse()
}
</script>

<style lang="scss" scoped>
.container {
  @include center-content;
  position: relative;
  aspect-ratio: 1/1;
}

.sprite {
  @include size(100%);
  position: relative;
  visibility: hidden;
  background-position: calc(100% / (v-bind(frames) - 1) * v-bind(frame)) 0;
  background-size: cover;
}

.icon {
  position: absolute;

  :deep(> *) {
    @include size(100%);
  }
}
</style>
